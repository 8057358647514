import styled from 'styled-components';
import theme from '../../theme';

export interface InfoImageSectionContainerProps {
  reverse?: boolean;
}

export const InfoImageSectionContainer = styled.div<InfoImageSectionContainerProps>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: ${(props: any) => (props.reverse ? 'row-reverse' : 'row')};
  padding: 50px 0;

  @media (max-width: ${theme.mediaBreakPoints.tabletMax}px) {
    flex-direction: column-reverse;
    margin-top: 50px;
    padding: 0;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: ${theme.mediaBreakPoints.tabletMax}px) {
    margin-bottom: 100px;
  }
`;

export const ImageItem = styled.img`
  width: 40%;
  user-select: none;
  pointer-events: none;

  @media (max-width: ${theme.mediaBreakPoints.tabletMax}px) {
    width: 100%;
  }
`;
