import React from 'react';
import Box from '@material-ui/core/Box';
import { LargeTitle, Callout, BodyText } from '../Typography';
import { InfoImageSectionContainer, InfoContainer, ImageItem } from './styles';

export interface InfoImageSectionProps {
  categoryText: string;
  title: string;
  description: string;
  image: string;
  imagePlacement?: 'left' | 'right';
}

const InfoImageSection = ({ categoryText, title, description, image, imagePlacement }: InfoImageSectionProps) => {
  const shouldReverse = imagePlacement === 'right';

  return (
    <InfoImageSectionContainer reverse={shouldReverse}>
      <InfoContainer>
        <Box width="90%" mb="15px">
          <Callout style={{ textTransform: 'uppercase', color: '#EC305E' }}>{categoryText}</Callout>
        </Box>
        <Box width="90%" mb="15px">
          <LargeTitle>{title}</LargeTitle>
        </Box>
        <Box width="90%">
          <BodyText>{description}</BodyText>
        </Box>
      </InfoContainer>
      <Box mx="35px" />
      <ImageItem src={image} />
    </InfoImageSectionContainer>
  );
};

export default InfoImageSection;
