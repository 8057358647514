import djApexLogo from '../../media/images/DJ_Avatars_FIN_DJ_Apex.png';
import djLynxLogo from '../../media/images/DJ_Avatars_FIN_DJ_Lynx.png';

export interface PreviewDJ {
  name: string;
  displayName: string;
  url: string;
  shortDescription: string;
  logoSrc: string;
}

export const previewDJs: PreviewDJ[] = [
  {
    name: 'dj_apex',
    displayName: 'DJ Apex',
    url: 'https://d1pvyh41t8vdlj.cloudfront.net/dj_apex/dj_apex.m3u8',
    shortDescription: '(Modern Hip Hop)',
    logoSrc: djApexLogo
  },
  {
    name: 'dj_lynx',
    displayName: 'DJ Lynx',
    url: 'https://d1pvyh41t8vdlj.cloudfront.net/dj_lyx/dj_lyx.m3u8',
    shortDescription: '(Dance/Pop)',
    logoSrc: djLynxLogo
  }
];
