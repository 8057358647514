import styled, { keyframes } from 'styled-components';
import Box from '@material-ui/core/Box';
import { ImageItemButton, ImageItemButtonProps } from '../Button';
import { Footnote2 } from '../Typography';
import theme from '../../../src/theme';

export const DjImagesContainer = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .MuiBox-root {
    position: relative;
  }
`;

const RotationKeyFrame = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

interface DjImageButtonProps extends ImageItemButtonProps {
  selected?: boolean;
  playing?: boolean;
}

export const DjImageButton = styled(ImageItemButton)<DjImageButtonProps>`
  img {
    width: 35%;
    margin-bottom: 10px;
    opacity: ${({ selected }) => (selected ? '1' : '0.5')};
    animation: ${RotationKeyFrame} ${({ playing }) => (playing ? '4s linear infinite' : 'paused')};

    // DISABLE ROTATION ANIMATION ON SAFARI...
    @media not all and (min-resolution: 0.001dpcm) {
      @supports (-webkit-appearance: none) {
        animation: none;
      }
    }
  }

  @media (max-width: ${theme.mediaBreakPoints.mobileMax}px) {
    img {
      width: 50%;
    }
  }
`;

export const DjImageContainer = styled(Box)`
  display: flex;
  flex-direction: column;
`;

export const PreviewText = styled(Footnote2)`
  color: ${theme.colors.NEUVYBE_MAIN_BUTTON_HOVER};
  margin: auto;
  font-size: 12px;
`;
