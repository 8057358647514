import React, { useState, useEffect } from 'react';
import Hls from 'hls.js';
import { DjImagesContainer, DjImageContainer, DjImageButton, PreviewText } from './styles';
import { SubHeadline, Footnote2 } from '../Typography';
import { previewDJs, PreviewDJ } from './config';
import theme from '../../../src/theme';
import mixpanel from 'mixpanel-browser';

interface DJ {
  audioElement: HTMLAudioElement;
  displayName: string;
  hls: Hls;
  url: string;
}

const Player = (): React.ReactElement => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [selectedDjIndex, setSelectedDjIndex] = useState(-1);
  const [djs, setDjs] = useState([] as DJ[]);
  const hlsConfig = {};

  const handlePlayerAnalytics = (djIndex: number, shouldStartPlaying: boolean): void => {
    const currentDJ = djs[djIndex];

    if (mixpanel && currentDJ) {
      shouldStartPlaying
        ? mixpanel.track('Play DJ', {
            dj: currentDJ.displayName,
            timestamp: new Date().toISOString()
          })
        : mixpanel.track('Pause DJ', {
            dj: currentDJ.displayName,
            timestamp: new Date().toISOString()
          });
    }
  };

  useEffect(() => {
    const resolvedPreviewDJs = previewDJs.map(previewDj => {
      const audioElement: HTMLAudioElement = document.createElement('audio');
      const canPlayM3u8 = audioElement.canPlayType('application/vnd.apple.mpegurl');
      const resolvedDJ = { ...previewDj, hls: new Hls(hlsConfig), audioElement };
      if (Hls.isSupported()) {
        resolvedDJ.hls.loadSource(previewDj.url);
        resolvedDJ.hls.attachMedia(audioElement as HTMLVideoElement);
      } else if (canPlayM3u8) {
        audioElement.src = previewDj.url;
      } else {
        console.error('FATAL ERROR > NO SUPPORT FOR HLS FOUND...');
      }
      return resolvedDJ;
    });

    setDjs(resolvedPreviewDJs);

    return (): void => {
      resolvedPreviewDJs.forEach(resolvedPreviewDj => {
        resolvedPreviewDj && resolvedPreviewDj.hls.destroy();
      });
    };
  }, []);

  const onDJClickHandler = (djIndex: number, isCurrentlyPlaying: boolean): void => {
    const currentlySelectedDJ = djs[djIndex] || null;

    if (!isCurrentlyPlaying || (isCurrentlyPlaying && selectedDjIndex !== djIndex)) {
      if (currentlySelectedDJ && currentlySelectedDJ.audioElement) {
        !isCurrentlyPlaying && setIsPlaying(true);
        djs.forEach(dj => {
          dj.audioElement.volume = 0;
          dj.audioElement.pause();
        });

        handlePlayerAnalytics(djIndex, true);

        currentlySelectedDJ.audioElement.volume = 1;
        currentlySelectedDJ.audioElement.play();

        if (selectedDjIndex !== djIndex) {
          setSelectedDjIndex(djIndex);
        }
      }
    } else if (isCurrentlyPlaying && selectedDjIndex === djIndex) {
      setIsPlaying(false);
      if (currentlySelectedDJ.audioElement && currentlySelectedDJ) {
        currentlySelectedDJ.audioElement.volume = 0;
        currentlySelectedDJ.audioElement.pause();
      }
      handlePlayerAnalytics(djIndex, false);
    }
  };

  return (
    <div>
      <div>
        <DjImagesContainer>
          {previewDJs &&
            previewDJs.map((dj: PreviewDJ, index) => {
              const selectedDJ = index === selectedDjIndex;
              const selectedDJPlaying = isPlaying && selectedDJ;
              return (
                <DjImageContainer key={index}>
                  <DjImageButton
                    playing={selectedDJPlaying}
                    selected={selectedDJ}
                    onClick={(): void => onDJClickHandler(index, isPlaying)}
                    src={dj.logoSrc}
                  />
                  <PreviewText>Preview Live</PreviewText>
                  <SubHeadline style={selectedDJPlaying ? { color: theme.colors.NEUVYBE_MAIN } : {}}>
                    {dj.displayName}
                  </SubHeadline>
                  <Footnote2>{dj.shortDescription}</Footnote2>
                </DjImageContainer>
              );
            })}
        </DjImagesContainer>
      </div>
    </div>
  );
};

export default Player;
