import React from 'react';
import Box from '@material-ui/core/Box';
import App from '../App';
import NavHeader from '../components/NavHeader';
import MainHero from '../components/MainHero';
import BottomHero from '../components/BottomHero';
import InfoImageSection from '../components/InfoImageSection';
import Seo from '../components/Seo';
import { GlobalStyles } from '../shared/global';
import theme from '../theme';

const Home = (): React.ReactElement => (
  <App>
    <Seo title="Home" />
    <GlobalStyles />
    <NavHeader navHeaderOffset={300} />
    <MainHero />

    <Box style={{ background: theme.colors.BACKGROUND_SECONDARY }} width="100%" mx="auto">
      <Box width="80%" mx="auto">
        <InfoImageSection
          categoryText="24/7"
          title="Diverse DJs Across Genres"
          description="We're excited to bring you live DJs playing your favorite songs 24/7, 365, across all genres of music. When  you tune in to our DJs you're able to experience what no other online streaming platform can bring you. Fresh, live mixed music that caters to all listeners tuned in."
          image={'/djs_image.png'}
          imagePlacement="left"
        />
      </Box>
    </Box>
    <Box style={{ background: theme.colors.BACKGROUND_MAIN }} width="100%" mx="auto">
      <Box width="80%" mx="auto">
        <InfoImageSection
          categoryText="Reactions"
          title="Community of Listeners"
          description="Express yourself with emojis! Actively interact with everyone else tuned into the current DJ. If you're diggin' the mixes let everyone know. Choose from dozens of your favorite emojis and see how other feel about the DJs mixes."
          image={'/emoji_image.png'}
          imagePlacement="right"
        />
      </Box>
    </Box>
    <Box style={{ background: theme.colors.BACKGROUND_SECONDARY }} width="100%" mx="auto">
      <Box width="80%" mx="auto">
        <InfoImageSection
          categoryText="Influence"
          title="Voting for Tracks"
          description="Actively give feedback to the current DJ to make the mixes improve. We use artificial Intelligence to predict which tracks make the best mixes. While the DJ mixes you'll have the option rate the last mix and even vote for upcoming songs!"
          image={'/tracks_image_.png'}
          imagePlacement="left"
        />
      </Box>
    </Box>
    <BottomHero />
  </App>
);

export default Home;
