import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import MainHeroImage from '../../media/images/hero_img.png';
import theme from '../../theme';

export const MainHeroContainer = styled.div`
  width: 100%;
  background: url(${MainHeroImage});
  background-size: cover;
  background-repeat: no-repeat;
`;

export const MainHeroContainerOverlay = styled.div`
  width: 100%;
  height: 100%;
  min-height: 776px;
  background-size: cover;
  background-repeat: no-repeat;
  // background: linear-gradient(180deg, rgba(14, 2, 26, 0.3) 0%, #0E021A 100%);
  // background: linear-gradient(to bottom, transparent 0%, #0f021a 100%);
  background: ${theme.colors.BACKGROUND_MAIN};
  opacity: 0.9;
`;

export const MainHeroInfoContainer = styled.div`
  position: relative;
  height: 100%;
`;

export const MainHeroInfo = styled(Box)`
  position: relative;
  text-align: center;
  margin: 0;
  padding-top: 150px;
  padding-bottom: 50px;
`;
